/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { UserDoc } from '../../schema/3/schema';

import { UtilService } from '../1/util.service';

const collectionPath = 'user';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: UserDoc;
  public latestUserSubject = new BehaviorSubject<UserDoc>(null);

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) {
  }

  public get organization() {
    if (this.user?.organization === undefined) {
      alert('필수정보(organization)가 없습니다. 개발자에게 알려주세요.');
    }
    return this.user.organization;
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  public observe(email: string) {
    console.log(`email = ${email}`);
    const docRef = this.db.doc<UserDoc>(`${collectionPath}/${email}`);

    docRef.valueChanges().subscribe(doc => {
      this.user = doc;

      this.latestUserSubject.next(doc);
    }, error => {
      this.utilService.toastrError(`UserService:observe에서 에러 발생 : ${error}`);
    });
  }
}

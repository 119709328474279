/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import get from 'lodash-es/get';
import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';

import { ColDef, ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core';

/**
 * Safari의 new Date()는 융통성이 없다.
 *
 * 다음의 형식을 인식하지 못한다.
 * 2019-01-01 11:11:12
 * 2019-01-01 11:11:12.0
 * 2019-01-01 11:11:12.123
 * 2019-01-01T11:11:12+0900
 * 2019-01-01T11:11:12+09:00
 *
 * 사파리를 지원하기 위한 코드가 추가되었다.
 *
 * ex) yyyy-MM-dd (ccc) HH:mm:ss
 *
 * refer: https://date-fns.org/v2.28.0/docs/format
 */
export function dateFormatter(value: Date | string | firestore.Timestamp, formatString: string): string {
  if (value === undefined) {
    return '';
  }

  // firestore.Timestamp의 경우 Date로 변환
  if (value instanceof firestore.Timestamp) {
    value = value.toDate();
  }

  // Date라면 바로 리턴
  if (value instanceof Date) {
    return format(value, formatString, { locale: ko });
  }

  if (typeof value === 'number') {
    return format(new Date(value), formatString, { locale: ko });
  }

  if (typeof value !== 'string') {
    return value;
  }

  // 2019/07/20 23:23:11.333 의 경우에 적용
  const date = value.replace(/\//g, '-');

  // 1561459027
  {
    const matches = date.match(/^(\d{10})$/);
    if (matches) {
      return format(new Date(parseInt(matches[0], 10) * 1000), formatString, { locale: ko });
    }
  }

  {
    const matches = date.match(/^(\d{13})$/);
    if (matches) {
      return format(new Date(parseInt(matches[0], 10)), formatString, { locale: ko });
    }
  }

  {
    // 2019-07-01T14:13:40+0900
    // 2019-07-01T14:13:40+09:00
    const matches = date.match(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})\+(\d{2}):{0,1}(\d{2})$/);
    if (matches) {
      return format(new Date(`${matches[1]}T${matches[2]}+${matches[3]}:${matches[4]}`), formatString, { locale: ko });
    }
  }

  {
    // 소수점은 무시. +0900으로 가정
    // 2019-07-01 14:13:40
    // 2019-07-01 14:13:40.0
    // 2019-07-01 14:13:40.123
    const matches = date.match(/^(\d{4}-\d{2}-\d{2}).(\d{2}:\d{2}:\d{2})/);
    if (matches) {
      return format(new Date(`${matches[1]}T${matches[2]}+09:00`), formatString, { locale: ko });
    }
  }

  return date;
}

export function addressFormatter(addr: string) {
  if (addr == null) {
    return addr;
  }

  const matches = addr.match(/^(?:서울|인천|대전|부산|대구|광주|울산|세종|경기|강원|충청|충남|충북|전라|전북|전남|경상|경북|경남|제주)\S*\s+(.+)$/);
  if (matches) {
    return matches[1];
  }

  return addr;
}

/**
 * - 를 삽입한다.
 * this 이슈를 피하기 위해서 util.service.ts에서 가져왔다.
 */
export function telFormatter(telNo: string) {
  if (telNo == null) {
    return '번호없음';
  }
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  // 2018-11-15 부터는 050으로 변환해서 FS에 저장하기 때문에 불펼요할 수 있다.
  telNo = telNo.replace(/^090/, '050');

  // 010- , 070-
  let matches = telNo.match(/^(0[17]0)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050은 4자리 식별번호를 사용하지만 3자리가 익숙하니 12자리가 아닌 경우에는 050에서 끊어준다.
  // 050-AAA?-BBBB
  matches = telNo.match(/^(050)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050X-AAAA-BBBB
  matches = telNo.match(/^(050.)(.{4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(02)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(0..)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 대표번호
  matches = telNo.match(/^1(.{3})(.{4})$/);
  if (matches) {
    return `1${matches[1]}-${matches[2]}`;
  }

  return telNo;
}

/**
 * refData 속성을 사용하는 것은 valueFormatter를 사용하는 효과가 있지만
 * 원본이 변경되지 않아서 export를 할 경우에 원본이 드러나게 된다.
 * 그래서 valueGetter를 이용해서 원본을 변경할 때 사용한다.
 */
export function valueGetterUsingMappings(params: ValueGetterParams, fieldPath: string, mappings: { [key: string]: string; }) {
  const doc = params.data;

  // rowGroup에 의한 empty row에서는 undefined가 된다.
  if (doc === undefined) {
    return '';
  }

  // foo.bar와 같은 형태의 fieldPath가 가능하다.
  let value = get(doc, fieldPath);

  //
  // 2019-07-14: 과거 데이터 중에 없는 경우에는 instanceNo로 부터 유추한다. 나중에는 불펼요
  if (['organization', 'site', 'room'].includes(fieldPath) && value === undefined) {
    value = doc.instanceNo ? this.confService.indexConfForInstanceId(doc.orderVendor, doc.instanceNo)[fieldPath] : '';
  }

  return mappings[value] === undefined ? '' : mappings[value];
}

/**
 * 초를 정수 분단위로 바꾼다.
 */
export function secondToMinute(second: number) {
  return Math.floor(second / 60);
}

/**
 * 날짜만 추출한다.
 */
export function dateOnlyGetter(params: ValueGetterParams, field: string) {
  const doc = params.data;
  if (doc == null) {
    return '';
  }
  // Safari는 +0900 형태에 대해서 에러를 리턴하기 때문에 parse후에 format을 한다.
  return format(parseISO(doc[field]), 'YYYY-MM-DD');
}

/**
 * 앞의 dateOnlyGetter와 함께 사용해서 요일을 표시해 준다.
 * 엑셀로 export 할 때는 요일을 붙이면 텍스트로 인식하기 때문에 붙이지 않고 표시할 때만 붙인다.
 */
export function dateOnlyFormatter(params: ValueFormatterParams) {
  const date: string = params.value;
  if (date === undefined || date === '') {
    return '';
  }
  // Safari는 +0900 형태에 대해서 에러를 리턴하기 때문에 parse후에 format을 한다.
  return format(parseISO(`${date}T00:00:00+0900`), 'YYYY-MM-DD ddd');
}

export function decimalFormatter(value: number | string | undefined, fractionDigits = 0) {
  if (value === undefined) {
    return '';
  }

  return Intl.NumberFormat('ko-KR', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }).format(Number(value));
}

/**
 * 별다른 포맷없이 그대로 표시한다.
 *
 * @param colDef 원하는 속성(ColDef)을 직접 지정하거나 변경할 수 있다.
 */
export function forPlain(headerName: string, field: string, width: number, colDef: Partial<ColDef> = {}): ColDef {
  return {
    ...{
      headerName, field, tooltipField: field, headerTooltip: `${headerName}(${field})`, width,
    },
    ...colDef
  };
}

export function forDynamicPlain(headerName: string, width: number, valueGetter: (params: ValueGetterParams) => any, colDef: Partial<ColDef> = {}): ColDef {
  return {
    ...{
      headerName, width, valueGetter
    },
    ...colDef
  };
}

/**
 * 별다른 포맷없이 그대로 표시한다.
 *
 * 적용 CSS class
 * - cellFixed
 *
 * @param colDef 원하는 속성(ColDef)을 직접 지정하거나 변경할 수 있다.
 */
export function forFixed(headerName: string, field: string, width: number, colDef: Partial<ColDef> = {}): ColDef {
  return {
    ...{
      headerName, field, tooltipField: field, headerTooltip: `${headerName}(${field})`, width,
      cellClass: ['cellFixed'],
    },
    ...colDef
  };
}

export function forDynamicFixed(headerName: string, width: number, valueGetter: (params: ValueGetterParams) => any, colDef: Partial<ColDef> = {}): ColDef {
  return {
    ...{
      headerName, width, valueGetter,
      cellClass: ['cellFixed'],
    },
    ...colDef
  };
}

/**
 * 숫자를 표현할 때 사용한다.
 * 소수점 이후의 폭을 동일하게 맞추어 정렬이 되도록 한다.
 *
 * 적용 CSS class
 * - cellRight
 * - cellFixed
 *
 * @param fractionDigits 소수점 이후 자리수를 지정한다. (디폴트: 0)
 * @param colDef 원하는 속성(ColDef)을 직접 지정하거나 변경할 수 있다.
 */
export function forDecimal(headerName: string, field: string, width: number, fractionDigits = 0, colDef: Partial<ColDef> = {}): ColDef {
  return forDynamicDecimal(headerName, width, undefined, fractionDigits, {
    ...{
      field, tooltipField: field, headerTooltip: `${headerName}(${field})`,
    },
    ...colDef
  });
}

// tslint:disable-next-line: max-line-length
export function forDynamicDecimal(headerName: string, width: number, valueGetter: (params: ValueGetterParams) => any, fractionDigits = 0, colDef: Partial<ColDef> = {}): ColDef {
  const additionalColDef: Partial<ColDef> = {};
  if (colDef.enableValue === true) {
    additionalColDef.aggFunc = 'sum';
    additionalColDef.allowedAggFuncs = ['sum', 'avg', 'min', 'max'];
  }

  const cellClass = (fractionDigits === 0) ? ['cellRight', 'cellFixed', 'wonType'] : ['cellRight', 'cellFixed'];

  return {
    ...{
      headerName, type: 'numericColumn', width, valueGetter,
      filter: 'agNumberColumnFilter',
      cellClass,
      valueFormatter: (params: ValueFormatterParams) => decimalFormatter(params.value, fractionDigits)
    },
    ...additionalColDef,
    ...colDef
  };
}

/**
 * 시간 필드를 지정한 date-fns 형식으로 표시한다.
 *
 * 적용 CSS class
 * - cellFixed
 *
 * @param timeFormat date-fns 형식의 포맷을 지정할 수 있다. (디폴트: 'HH:mm:ss')
 * @param colDef 원하는 속성(ColDef)을 직접 지정하거나 변경할 수 있다.
 */
export function forTime(headerName: string, field: string, width: number, timeFormat = 'HH:mm:ss', colDef: Partial<ColDef> = {}): ColDef {
  return {
    ...{
      headerName, field, tooltipField: field, headerTooltip: `${headerName}(${field})`, width,
      cellClass: ['cellFixed'],
      valueFormatter: (params: ValueFormatterParams) => dateFormatter(params.value, timeFormat)
    },
    ...colDef
  };
}

/**
 * 타임스탬프 필드를 지정한 date-fns 형식으로 표시한다.
 *
 * 적용 CSS class
 * - cellFixed
 *
 * @param timeFormat date-fns 형식의 포맷을 지정할 수 있다. (디폴트: 'HH:mm:ss')
 * @param colDef 원하는 속성(ColDef)을 직접 지정하거나 변경할 수 있다.
 */
export function forTimestamp(headerName: string, field: string, width: number, timeFormat = 'HH:mm:ss', colDef: Partial<ColDef> = {}): ColDef {
  return {
    ...{
      headerName, field, headerTooltip: `${headerName}(${field})`, width,
      cellClass: ['cellFixed'],
      tooltipValueGetter: (params) => dateFormatter(get(params.data, field, ''), `yyyy-MM-dd'T'HH:mm:ss`),
      valueFormatter: params => dateFormatter(params.value, timeFormat),
      valueGetter: (params: ValueGetterParams) => {
        return dateFormatter(get(params.data, field, ''), `yyyy-MM-dd'T'HH:mm:ss+09:00`);
      }
    },
    ...colDef
  };
}

/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AgGridModule } from '@ag-grid-community/angular';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';

import { environment } from '../../environments/environment';

import { SafePipe } from './safe.pipe';
import { DialogNoticeComponent } from './dialog-notice/dialog-notice.component';
import { DialogSpinnerComponent } from './dialog-spinner/dialog-spinner.component';
import { DialogLoginComponent } from './dialog-login/dialog-login.component';
import { DateTimeSelectorComponent } from './date-time-selector/date-time-selector.component';
import { AccountFilterComponent } from './account-filter/account-filter.component';
import { TimerComponent } from './timer/timer.component';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { DialogJsonEditorComponent } from './dialog-json-editor/dialog-json-editor.component';
import { DialogConfirmOptionsComponent } from './dialog-json-editor/dialog-confirm-options/dialog-confirm-options.component';
import { DateTimeRangePickerComponent } from './date-time-range-picker/date-time-range-picker.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { DialogRawDataComponent } from './dialog-raw-data/dialog-raw-data.component';
import { DialogCombinenetStatusComponent } from './dialog-combinenet-status/dialog-combinenet-status.component';
import { DialogVroongStatusComponent } from './dialog-vroong-status/dialog-vroong-status.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatSelectModule,
    MatListModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatTabsModule,
    OverlayModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,

    AgGridModule,
  ],
  declarations: [
    SafePipe,
    DialogNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
    DateTimeSelectorComponent,
    AccountFilterComponent,
    TimerComponent,
    DialogConfirmComponent,
    DialogJsonEditorComponent,
    DialogConfirmOptionsComponent,
    DateTimeRangePickerComponent,
    CustomTooltipComponent,
    DialogRawDataComponent,
    DialogCombinenetStatusComponent,
    DialogVroongStatusComponent,
  ],
  exports: [
    SafePipe,
    DateTimeSelectorComponent,
    AccountFilterComponent,
    TimerComponent,
    DateTimeRangePickerComponent,
    DialogCombinenetStatusComponent,
  ],
  providers: [
    // SETTINGS DI Token은 angularfire 소스 코드에서 찾았다.
    // https://firebase.google.com/docs/reference/js/firebase.firestore.Settings#optional-ignoreundefinedproperties
    { provide: SETTINGS, useValue: { ignoreUndefinedProperties: true } }
  ]
})
export class SharedModule { }

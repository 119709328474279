/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface SearchAccount {
  id: string;
  domain: string;
}

@Component({
  selector: 'app-account-filter',
  templateUrl: './account-filter.component.html',
  styleUrls: ['./account-filter.component.scss']
})
export class AccountFilterComponent implements OnInit {
  @Output() account = new EventEmitter<string>();

  searchAccount: SearchAccount = {
    id: '',
    domain: ''
  };

  domains: string[];
  idControl: FormControl;
  domainControl: FormControl;

  constructor() { }

  ngOnInit(): void {
    this.domains = [
      'buttonsoup.co',
      'toe.cloud',
      'ghostaurant.co',
      'ceo.stat'
    ];

    this.idControl = new FormControl(this.searchAccount.id);
    this.domainControl = new FormControl(this.searchAccount.domain);
  }

  observeWithAccount() {
    this.searchAccount.id = this.idControl.value.replace(/ /g, '');
    this.searchAccount.domain = this.domainControl.value.replace(/ /g, '');

    this.account.emit(`${this.searchAccount.id}@${this.searchAccount.domain}`);
  }
}

/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { environment } from '../environments/environment';

import { UtilService } from './core/1/util.service';
import { IpService } from './core/1/ip.service';
import { roleMappings } from './core/1/string-map';
import { VersionService } from './core/1/version.service';
import { AuthService } from './core/2/auth.service';
import { sleep } from './core/2/util';
import { UserService } from './core/2/user.service';
import { LogService } from './core/3/log.service';
import { SiteService } from './core/3/site.service';
import { MessageService } from './core/4/message.service';
import { RoomService } from './core/4/room.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isDev = false;
  public loggedIn = false;
  public email = '';
  public myVersion: string;
  public latestVersion: string;

  public title = '발가락 Admin';
  public navItems = [
    { name: 'journal', route: '/journal' },
    { name: '연대기', route: '/timetable' },
    { name: 'divider' },
    { name: '주문 생성기', route: '/order-generator' },
    { name: '배차 편집기', route: '/unified-delivery' },
    { name: 'firestore 조회', route: '/firestore' },
    { name: 'divider' },
    { name: 'logOrder', route: '/log-order' },
    { name: 'logBilling', route: '/log-billing' },
    { name: 'log', route: '/log' },
    { name: 'message', route: '/message' },
    { name: '다이렉트 Analytics', route: '/direct-analytics' },
    { name: 'divider' },
    { name: 'user', route: '/user' },
    { name: 'search-address', route: '/search-address' },
    { name: '연동 계정', route: '/account-docs' },
    { name: '프로세스 상태', route: '/process-status' },
    { name: '데몬 스위치', route: '/daemon-switch' },
    { name: 'divider' },
    { name: '배민 앱 목록 주문', route: '/baemin-app-list-order' },
    { name: '배민 앱 상세 주문', route: '/baemin-app-detail-order' },
    { name: '배민 CEO 업소 Open', route: '/baemin-ceo-shops-open' },
    { name: 'divider' },
    { name: '쿠팡이츠 주문', route: '/coupangeats-app-order' },
    { name: '쿠팡이츠 주문 조회', route: '/coupangeats-app-order-range' },
    { name: 'divider' },
    { name: '요기요 앱 주문', route: '/yogiyo-app-list-order' },
    { name: 'divider' },
    { name: '컴바인넷 배송', route: '/combinenet-delivery' },
    { name: '컴바인넷 배송 조회', route: '/combinenet-delivery-range' },
    { name: '컴바인넷 상점', route: '/combinenet-shop' },
    { name: '컴바인넷 콜백', route: '/combinenet-callback' },
    { name: 'divider' },
    { name: '바로고 배송', route: '/barogo-delivery' },
    { name: '바로고 배송 조회', route: '/barogo-delivery-range' },
    { name: '바로고 업소', route: '/barogo-shop' },
    { name: 'divider' },
    { name: '생각대로 배송', route: '/logiall-delivery' },
    { name: '생각대로 배송 조회', route: '/logiall-delivery-range' },
    { name: 'divider' },
    { name: '만나플래닛 배송', route: '/manna-delivery' },
    { name: '만나플래닛 배송 조회', route: '/manna-delivery-range' },
    { name: 'divider' },
    { name: '부릉 배송 조회', route: '/vroong-pos-delivery-range' },
    { name: '부릉 픽업 옵션', route: '/vroong-pos-options-pickup' },
    { name: 'divider' },
    { name: '센트렉스 콜백', route: '/centrex-callback' },
    { name: '문자 수신', route: '/sms-receive' },
    { name: 'divider' },
    { name: '컴바인넷 API', route: '/combinenet' },
    { name: '바로고 API', route: '/barogo' },
    { name: '생각대로 API', route: '/logiall' },
    { name: '만나 API', route: '/manna' },
    { name: '젠딜리 API', route: '/zendeli' },
    { name: '모아플래닛 API', route: '/moaplanet' },
    { name: '국민라이더스 API', route: '/iudream' },
    { name: '배달요 API', route: '/baedalyo' },
    { name: 'divider' },
    { name: 'KICC 주문', route: '/kicc-order' },
    { name: 'KICC 결제승인결과', route: '/kicc-result' },
    { name: 'KICC 결제알림', route: '/kicc-notification' },
    { name: 'divider' },
    { name: '조추첨', route: '/grouping' },
    { name: '도서관', route: '/book' },
    { name: 'sanitizer 테스트', route: '/sanitizer' },
    { name: 'playground', route: '/playground' },
    { name: 'functions-ping', route: '/functions-ping' },
  ];

  private subscription: Subscription = null;
  private versionSubscription: Subscription = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private utilService: UtilService,
    private messageService: MessageService,
    private versionService: VersionService,
    private userService: UserService,
    private ipService: IpService,
    private roomService: RoomService,
    private siteService: SiteService,
    private logService: LogService
  ) {
    this.isDev = environment.firebase?.projectId !== 'toe-prod';

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.dir(router.routerState);
        console.log(`urlAfterRedirects = ${event.urlAfterRedirects}`);

        for (const navItem of this.navItems) {
          // ex. /firestore/baeminAppDetailOrder/B0MP01BVXL
          const rootPath = '/' + event.urlAfterRedirects.split('/')[1];
          // console.log(`rootPath = ${rootPath}`);
          if (navItem.route === rootPath) {
            this.title = `발가락 Admin - ${navItem.name}`;
            document.title = navItem.name;
            break;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.subscription = this.authService.observeLoggedIn().pipe(
      // 안정화되지 않은 상태인 null 을 제거하기 위함이다.
      filter(value => {
        return value === true || value === false;
      })
    ).subscribe(async value => {
      console.log(`@AppComponent loggedIn = ${value}`);
      this.loggedIn = value;
      if (value === true) {
        this.utilService.toastrInfo('로그인 성공!', null, 3000);
        this.email = this.authService.user.email;

        let localAddresses = 'unknown';
        try {
          localAddresses = (await this.ipService.findLocalAddress()).join();
        } catch (err) {
          console.error('Fail to local IP address');
        }
        const publicAddress = await this.ipService.findPublicAddress();

        console.log(`localAddress = ${localAddresses}`);
        console.log(`publicAddress = ${publicAddress}`);

        // 아래 3가지는 init-guard의 조건이기도 하다.
        // 여기에서 시작을 하고 init-guard에서는 데이터가 수신 완료되는 것을 기다린다.
        this.userService.observe(this.email);
        this.userService.latestUserSubject
          .subscribe(user => {
            if (user) {
              if (!['admin'].includes(user.role)) {
                this.logService.withToastrError(`현재 사용자(${user.email}:${user.role})는 권한이 없습니다. 로그아웃합니다.`);
                this.logout();
              }
            }
          });

        while (this.userService.user === undefined) {
          console.log('Waiting for the first sync with Firestore user');
          await sleep(200);
        }

        // message에 기록을 남긴다.
        this.messageService.notificationLogin(this.email, {
          localIPs: localAddresses ? localAddresses : 'N/A',
          publicIP: publicAddress ?? 'N/A'
        });

        this.siteService.observe();
        this.roomService.observe();
      }

      // subscribe로 변경하면서 아래의 문제가 저절로 해결된다.
      // refer: https://stackoverflow.com/questions/35105374/how-to-force-a-components-re-rendering-in-angular-2
      // LoginComponent가 붙을 경우에 별다른 일을 하지 않으니 AppComponent의 뷰가 갱신되지 않았다.
      // this.changeDetectorRef.detectChanges();
    });

    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;
    this.versionSubscription = this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      this.latestVersion = lastesVersion;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.versionSubscription) {
      this.versionSubscription.unsubscribe();
    }
  }

  get role() {
    return this.userService.user ? roleMappings[this.userService.user.role] : '...';
  }

  logout() {
    this.logService.info('로그아웃');

    this.authService.signOut().then(value => {
      this.messageService.notificationLogout();
      this.router.navigate(['/auth/login']);

      // 로그아웃을 하면 기존 상태도 초기화해야 한다.
      // 여러 서비스가 갖고 있는 상태를 초기화하기 위해서 일단 reload()한다.
      // TODO : 나중에는 상태를 정리한다 리로드 할 경우에 토스트 메시지가 사라지는 문제가 있다.
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }

  reload() {
    window.location.reload();
  }

  // private injectionTest() {
  //   const injector = (window as any).rootInjector as Injector;
  //   console.dir(injector);
  //   const logService = injector.get(LogService);
  //   logService.withToastrError('코드를 통한 서비스 접근');
  // }
}

/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';
import cloneDeep from 'lodash-es/cloneDeep';  // refer: https://medium.com/@armno/til-importing-lodash-into-angular-the-better-way-aacbeaa40473
import get from 'lodash-es/get';

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { VroongPosEstimate } from '../../schema/1/schema-vroong-api';
import { Message } from '../../schema/2/schema-message';
import { VroongPosDeliveryDoc } from '../../schema/2/schema-vroong';

import { telFormatter } from '../../core/1/ag-util';
import { vroongStatusMappings, vroongPayMethodMappings } from '../../core/1/string-map';
import { toDate, diffTime } from '../../core/2/util';
import { MessageService } from '../../core/4/message.service';

import { DialogSpinnerService } from '../../shared/dialog-spinner/dialog-spinner.service';
import { DialogSpinnerComponent } from '../../shared/dialog-spinner/dialog-spinner.component';

interface TIMESTAMP {
  name: string;
  path?: string;
  date?: Date;
  relativeToCreatedAt?: string;
  relativeToNow?: string;
  class?: string;
}

@Component({
  selector: 'app-dialog-vroong-status',
  templateUrl: './dialog-vroong-status.component.html',
  styleUrls: ['./dialog-vroong-status.component.scss']
})
export class DialogVroongStatusComponent implements OnInit, OnDestroy {
  delivery: VroongPosDeliveryDoc;
  private spinnerDialogRef: MatDialogRef<DialogSpinnerComponent, any>;

  // UI에 적합한 형태로 변환
  status: string;
  payMethod: string;
  agentTel: string;
  userTel: string;

  sortedTimes: TIMESTAMP[];
  times: {
    [key: string]: TIMESTAMP;
  } = {
      created_at: {
        name: '신청',
        path: 'created_at',
      },
      assigned_at: {
        name: '배차',
        path: 'delivery_tracking_status.assigned_at',
      },
      cargo_prepared_at: {
        name: '*조리완료*',
        path: 'delivery_tracking_status.cargo_prepared_at',
      },
      picked_up_at: {
        name: '픽업',
        path: 'delivery_tracking_status.picked_up_at',
      },
      requested_pick_up_at: {
        name: '*요청픽업*',
        path: 'requested_pick_up_at',
      },
      pickup_expected_at: {
        name: '*조정픽업*',
        class: 'notice'
      },
      delivered_at: {
        name: '완료',
        path: 'delivery_tracking_status.delivered_at',
      },
      now: {
        name: '--- 현재 ---',
        class: 'blink'
      },
      estimated_arrived_at: {
        name: '*예상완료*',
      }
    };

  constructor(
    public dialogRef: MatDialogRef<DialogVroongStatusComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: { delivery: VroongPosDeliveryDoc },
    private dialogSpinnerService: DialogSpinnerService,
    private messageService: MessageService
  ) {
    this.delivery = cloneDeep(data.delivery);
  }

  ngOnInit() {
    if (this.delivery.status === 'ASSIGNED' || this.delivery.status === 'PICKED_UP') {
      this.updateEstimate();
    }
    this.status = vroongStatusMappings[this.delivery.status];
    this.payMethod = vroongPayMethodMappings[this.delivery.vroong_cargo_price_pay_method];
    if (this.delivery.delivery_tracking_status.agent_phone) {
      this.agentTel = telFormatter(this.delivery.delivery_tracking_status.agent_phone);
    }
    this.userTel = telFormatter(this.delivery.dest_phone);

    this.updateTimes();
  }

  ngOnDestroy() {
    this.dialogRef?.close();
    this.spinnerDialogRef?.close();
  }

  updateTimes() {
    // delivery 속성만 업데이트 (path가 있는 경우)
    for (const timestamp of Object.values(this.times)) {
      const path = timestamp.path;
      if (path) {
        const timeString = get(this.delivery, path, undefined);
        if (timeString) {
          timestamp.date = toDate(timeString);
        }
      }
    }

    // 픽업 조정
    // 픽업 조정이 있다면 가장 최근의 픽업 조정 시각을 기준으로 하고
    // 그렇지 않다면 최초 요청 픽업 시각을 기준으로 한다.
    const delivery = this.delivery;
    if (delivery.delivery_pickup_adjustments && delivery.delivery_pickup_adjustments.length > 0) {
      this.times.pickup_expected_at.date = toDate(delivery.delivery_pickup_adjustments[delivery.delivery_pickup_adjustments.length - 1].pickup_expected_at);
      // UI를 달리 표현하기 위해 사용한다.
    }

    // now 업데이트
    this.times.now.date = new Date();

    // filter and sort
    this.sortedTimes = Object.values(this.times)
      .filter(timestamp => timestamp.date)
      .sort((t1, t2) => {
        return t1.date < t2.date ? -1 :
          t1.date > t2.date ? 1 : 0;
      });

    const now = this.times.now.date;
    const createdAt = this.times.created_at.date;

    // 상대시간 업데이트
    for (const timestamp of this.sortedTimes) {
      const t2 = timestamp.date;
      {
        const t1 = createdAt;
        const { m, sStr } = t1 <= t2 ? diffTime(t1, t2) : diffTime(t2, t1);
        timestamp.relativeToCreatedAt = t1 <= t2 ? `${m}:${sStr}` : `-${m}:${sStr}`;
      }
      {
        const t1 = now;
        const { m, sStr } = t1 <= t2 ? diffTime(t1, t2) : diffTime(t2, t1);
        timestamp.relativeToNow = t1 <= t2 ? `${m}:${sStr}` : `-${m}:${sStr}`;
      }
    }
  }

  onSubmit() {
    this.dialogRef?.close(false);
    this.dialogRef = undefined;
  }

  async cancelDelivery() {
    const delivery = this.delivery;
    const deliveryId = delivery.delivery_number;

    this.spinnerDialogRef = this.dialogSpinnerService.openSpinnerDialog('취소 요청 보내는 중');

    try {
      const responseMessage = await this.messageService.requestCancelVroongDelivery(delivery._bizNo, deliveryId) as Message<'response', 'cancelVroongDelivery'>;

      this.spinnerDialogRef?.close(true);
      this.spinnerDialogRef = undefined;

      const body = responseMessage.body;
      if (responseMessage.result !== 'success') {
        if (body) {
          Swal.fire(body.error_type, `[${body.error_code}] ${body.error_message}`);
        } else {
          Swal.fire('실패', responseMessage.reason);
        }
      } else {
        this.dialogRef?.close(true);
        this.dialogRef = undefined;
      }
    } catch (err) {
      console.error(err);

      this.spinnerDialogRef?.close(true);
      this.spinnerDialogRef = undefined;

      if (err !== false) {
        Swal.fire(err.message);
      }
    }
  }

  /**
   * 배송 완료 예상 시간을 얻어오기 위한 과정은 다음과 같다.
   * 1. messsage collection을 이용해서 vroong-pos-proxy에 명령을 전달한다.
   * 2. vroong-pos-proxy는 명령을 받으면 부릉 POS API를 이용해서 완료 예상 시간을 가져온다.
   * 3. vroong-pos-proxy는 받은 응답을 message를 이용해서 OMC에 전달한다.
   * 4. 응답을 기다리고 있는 OMC는 응답을 받아 처리한다.
   */
  async updateEstimate() {
    const delivery = this.delivery;

    try {
      // tslint:disable-next-line: max-line-length
      const messageResponse = await this.messageService.requestEstimateVroongDelivery(delivery._bizNo, delivery.delivery_number) as Message<'response', 'estimateVroongDelivery'>;

      if (messageResponse.result === 'success') {
        const estimate = messageResponse.body as VroongPosEstimate;
        // 도중에 기사가 재할당되는 경우가 있고 SUBMITTED인 경우에는 경우에는 expeted_at이 null이다.
        if (estimate.arrival.expected_at) {
          this.times.estimated_arrived_at.date = toDate(estimate.arrival.expected_at);
          this.updateTimes();
        }
      } else {
        Swal.fire('배송 완료 예상 시간 조회 에러', messageResponse.reason);
      }
    } catch (err) {
      console.error(err);

      Swal.fire(err.message);
    }
  }

  cargoPrepared() {
    this.spinnerDialogRef = this.dialogSpinnerService.openSpinnerDialog('요청 보내는 중');
    // 주의: agInit에서 delivery를 저장한 후에 사용하면 최신 delivery를 추적하지 않는다.

    const promise = this.messageService.requestPreparedCargoVroongDelivery(this.delivery._bizNo, this.delivery.delivery_number);

    promise.then(() => {
      this.spinnerDialogRef?.close(true);
      this.spinnerDialogRef = undefined;
    }, error => {
      this.spinnerDialogRef?.close(false);
      this.spinnerDialogRef = undefined;

      if (error !== false) {
        Swal.fire(error.message);
      }
    });
  }

  openDestMap() {
    const delivery = this.delivery;
    // tslint:disable-next-line: max-line-length
    const name = encodeURI(`[${delivery.distance}m] ${delivery.dest_address.beonji_address.raw_address} (${delivery.dest_address.road_address.raw_address})${delivery.delivery_tracking_status.agent_name ? ' [' + delivery.delivery_tracking_status.agent_name + ']' : ''}`)
      .replace(/,/g, ''); // COMMA 제거
    const url = 'http://map.daum.net/link/map/' + name + `,${delivery.dest_address.latlng.lat},${delivery.dest_address.latlng.lng}`;

    // refer : http://apis.map.daum.net/web/guide/#bigmapurl
    // http://map.daum.net/?urlX=506255.0&urlY=1113450.0&name=%EB%85%BC%ED%98%84%EB%8F%99%2B170-14
    window.open(
      url,
      delivery.dest_address.beonji_address.raw_address, // 번지가 다르면 새 창에서 열리도록 하기 위함
      'centerscreen,width=1600,height=1080,alwaysRaised,noreferrer'
    );
  }
}

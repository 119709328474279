/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import stringify from 'json-stable-stringify';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UtilService } from '../../core/1/util.service';
import { LogService } from '../../core/3/log.service';
import { CollectionPath, FirebaseManagerService } from '../../core/4/firebase-manager.service';
import { environment } from '../../../environments/environment';

import { DialogConfirmOptionsService } from './dialog-confirm-options/dialog-confirm-options.service';

@Component({
  selector: 'app-dialog-json-editor',
  templateUrl: './dialog-json-editor.component.html',
  styleUrls: ['./dialog-json-editor.component.scss']
})
export class DialogJsonEditorComponent implements OnInit {
  public jsonEditorForm: FormGroup;
  public jsonDocToHtml: string;

  public collectionName: CollectionPath;
  public docId: string;

  private jsonDoc: any;

  constructor(
    public dialogRef: MatDialogRef<DialogJsonEditorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { collectionName: CollectionPath, docId: string },
    private firebaseManager: FirebaseManagerService,
    private utilService: UtilService,
    private logService: LogService,
    private dialogConfirmOptionsService: DialogConfirmOptionsService
  ) { }

  async ngOnInit() {
    this.docId = this.data.docId;
    this.collectionName = this.data.collectionName;
    this.initForm();

    /* doc 데이터를 불러옴 */
    this.jsonDoc = await this.firebaseManager.getDoc(`${this.collectionName}/${this.docId}`);

    this.makeJsonToHtml(this.jsonDoc);
    this.jsonEditorForm.get('textJsonDoc').setValue(stringify(this.jsonDoc, { space: 2 }));
  }

  public jsonValidator() {
    return (control: FormControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      const form = control.parent;

      try {
        const json = JSON.parse(form.get('textJsonDoc').value);
        this.makeJsonToHtml(json);

        return null;
      } catch (error) {
        console.error(error);

        return { reason: `JSON 형식 오류 ${error}` };
      }
    };
  }

  // console 웹으로 이동
  public goToConsole(consoleType: 'gcp' | 'firebase') {
    const projectName = environment.production ? 'toe-prod' : 'toe-dev-2019';
    if (consoleType === 'gcp') {
      window.open(`https://console.cloud.google.com/firestore/data/${this.collectionName}/${this.docId}?project=${projectName}`, '_blank');
    } else {
      window.open(`https://console.firebase.google.com/project/${projectName}/firestore/data~2F${this.collectionName}~2F${this.docId}`, '_blank');
    }
  }

  public openConfirmDialog() {
    const textJsonDoc = this.jsonEditorForm.get('textJsonDoc').value;

    try {
      this.jsonDoc = JSON.parse(textJsonDoc);
    } catch (error) {
      this.utilService.toastrCatch(error, 'JSON 형식 오류');
      return;
    }

    const dialogConfirmRef = this.dialogConfirmOptionsService.openDialog();

    dialogConfirmRef.afterClosed().subscribe(result => {
      switch (result) {
        case 'SET':
          this.onSet(false);
          break;
        case 'MERGE':
          this.onSet(true);
          break;
        case 'UPDATE':
          this.onUpdate();
          break;
      }
    });
  }

  public onCloseDialog() {
    this.dialogRef?.close(false);
    this.dialogRef = undefined;
  }

  private initForm() {
    this.jsonEditorForm = new FormGroup({
      textJsonDoc: new FormControl('{}', this.jsonValidator())
    });
  }

  private makeJsonToHtml(json: any) {
    const jsonToHtml = stringify(json, { space: 2 });
    this.jsonDocToHtml = Prism.highlight(jsonToHtml, Prism.languages.json, 'json');
  }

  private onSet(isMerge: boolean = false) {
    const setType = isMerge ? 'merge' : 'set';

    this.firebaseManager.setDoc(this.collectionName, this.docId, this.jsonDoc, { bMerge: isMerge }).then(() => {
      this.utilService.toastrInfo(`${setType} 완료`, undefined, 3000);
      this.onCloseDialog();
    }, error => {
      this.logService.withToastrCatch(error, `dialog-json-editor [${setType}] 예외 발생`);
    });
  }

  private onUpdate() {
    this.firebaseManager.updateDoc(this.collectionName, this.docId, this.jsonDoc).then(() => {
      this.utilService.toastrInfo('update 완료', undefined, 3000);
      this.onCloseDialog();
    }, error => {
      this.logService.withToastrCatch(error, 'dialog-json-editor [update] 예외 발생');
    });
  }
}

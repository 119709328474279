/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanActivate, Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild, CanLoad
} from '@angular/router';
import { UserService } from './core/2/user.service';
import { SiteService } from './core/3/site.service';
import { RoomService } from './core/4/room.service';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - conf/organization
 */
@Injectable({
  providedIn: 'root'
})
export class InitGuardService implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private userService: UserService,
    private roomService: RoomService,
    private siteService: SiteService
  ) { }

  promiseForInit() {
    return new Promise<boolean>(async (resolve, reject) => {
      const userOb = this.userService.latestUserSubject;
      const siteOb = this.siteService.latestSubject;
      const roomOb = this.roomService.latestSubject;

      try {
        await combineLatest([userOb, siteOb, roomOb]).pipe(
          takeWhile(([user, site, room]) => !(user?.role && Object.keys(site).length > 0 && Object.keys(room).length > 0))
        ).forEach(() => { /* 끝나기만 기다릴 뿐 */ });
      } catch (err) {
        reject(err);
      }

      resolve(true);
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(`canActivate: '${route.url}'`);

    return this.promiseForInit();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(`canActivateChild: '${childRoute.url}'`);

    return this.promiseForInit();
  }

  canLoad(route: Route) {
    // console.log(`canLoad: '${route.path}'`);

    return this.promiseForInit();
  }
}

/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string },
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
  ) { }

  ngOnInit(): void {
  }

  public onCloseDialog(result: 'cancel' | 'ok') {
    this.dialogRef?.close(result);
    this.dialogRef = undefined;
  }
}

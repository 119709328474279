/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogJsonEditorComponent } from './dialog-json-editor.component';

@Injectable({
  providedIn: 'root'
})
export class DialogJsonEditorService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(collectionName: string, docId: string) {
    // 다른 다일로그는 닫는다.
    this.dialog.closeAll();

    const dialogRef = this.dialog.open(DialogJsonEditorComponent, {
      data: {
        collectionName,
        docId
      },
      width: '80vw',
      height: '80vh'
    });

    return dialogRef;
  }
}

/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { race, NEVER } from 'rxjs';
import { map, timeout, filter, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { BaeminCancelReasonCode } from '../../schema/1/schema-baemin-common';
import { Message, MessageBodyMap, MessagePeer, RequestMessageBodyCreateVroongDelivery } from '../../schema/2/schema-message';

import { UtilService } from '../1/util.service';
import { instanceId } from '../1/common';
import { UserService } from '../2/user.service';
import { LogService } from '../3/log.service';

import { environment } from '../../../environments/environment';

const collectionPath = 'message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private userService: UserService,
    private logService: LogService,
  ) {
    console.log(`message instance ID = ${instanceId}`);
  }

  observeMessageRange(startDate: Date, endDate: Date, orderBy: 'asc' | 'desc' = 'asc') {
    console.log(`observeMessage date:${startDate}~${endDate}`);

    const organization = this.userService.organization;
    const queryFn: QueryFn = ref => {
      let query = ref
        .where('organization', '==', organization)
        .orderBy('_timeCreate', orderBy)[orderBy === 'asc' ? 'startAt' : 'endBefore'](firestore.Timestamp.fromDate(startDate));
      query = query[orderBy === 'asc' ? 'endAt' : 'startAfter'](firestore.Timestamp.fromDate(endDate));
      return query;
    };

    const messageCollection = this.db.collection<Message<any, any>>(collectionPath, queryFn);

    // 디버깅용
    if (environment.production === false) {
      messageCollection.stateChanges().pipe(
        map(actions => actions.map(action => {
          return { _type: action.type, ...action.payload.doc.data() };
        }))
      ).subscribe(orders => {
        for (const order of orders) {
          // console.log(`[${scooter.vendor}] ${scooter.no} '${scooter._type}'`);
        }
      });
    }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = messageCollection.valueChanges();

    return observable;
  }

  /**
   * 지정 시각 이후의 메시지 전체를 받는다.
   */
  observeMessage() {
    const now = new Date();
    // const from = now.getTime() - 10 * 24 * 3600 * 1000;

    console.log(`${this.constructor.name}::observeMessage from ${now}`);
    const organization = this.userService.organization;
    const queryFn: QueryFn = ref => {
      return ref
        .where('organization', '==', organization)
        .where('channel', '==', 'message')
        .where('type', '==', 'response')
        .where('to.class', '==', 'omc')
        .where('to.instanceNo', '==', instanceId)
        .where('_timeCreate', '>', now);
    };

    const messageCollection = this.db.collection<Message<'response', any>>(collectionPath, queryFn);

    messageCollection
      .stateChanges()
      .pipe(
        map(actions =>
          actions.map(action => {
            // _type 필드 추가
            return { _type: action.type, ...action.payload.doc.data() };
          })
        )
      )
      .subscribe(messages => {
        for (const message of messages) {
          if (message._type === 'added') {
            console.log(`message response received : ${message.name}`);
            this.handleResponse(message);
          } else {
            console.error(`${message.name} ${message._type}`);
          }
        }
      }, error => {
        this.logService.withToastrError(`observeMessage에서 에러 발생 : ${error}`);
      });
  }

  /**
   * requestId로 요청한 메시지의 응답을 지정 시간까지 기다린다.
   *
   * @param requestId request document의 ID
   * @param msec 밀리초
   */
  private observeResponseWithTimeout(requestId: string, msec = 10000) {
    // 복합 색인을 피하기 위해서 requestId에 대해서만 조건을 주었다.
    const queryFn: QueryFn = ref => {
      return ref
        .where('requestId', '==', requestId);
    };

    const messageCollection = this.db.collection<Message<'response', any>>(collectionPath, queryFn);

    return new Promise((resolve, reject) => {
      // refer: https://stackoverflow.com/questions/46886073/rxjs-timeout-to-first-value
      // race는 2개의 observable 중에 1개의 observable을 선택하는 것이지 한 개의 값을 취하는 것이 아니다.
      // 그렇기 때문에 complete이 되는 것도 아니다.
      // complete이 되게 하기 위해서
      // 1. filter로 원치 않는 응답은 거르고
      // 2. take()로 1개만 취했다.
      const messageOb = messageCollection
        .stateChanges()
        .pipe(
          map(actions =>
            actions.map(action => {
              // _type 필드 추가
              return { _type: action.type, ...action.payload.doc.data() };
            })
          ),
          filter(messages => messages.length > 0),  // 최초의 빈 배열은 거른다.
          take(1)
        );
      const timeoutOb = NEVER.pipe(timeout(msec));

      race(messageOb, timeoutOb).subscribe(messages => {
        console.log(`next :`);
        console.dir(messages);

        for (const message of messages) {
          if (message._type === 'added') {
            console.log(`message response for ${requestId}/${message.name} received`);
            resolve(message);
          } else {
            console.error(`${message.name} ${message._type}`);
          }
        }
      }, error => {
        // timeout인 경우에는 다음의 형식을 리턴
        // {
        //   message: "Timeout has occurred"
        //   name: "TimeoutError"
        //   stack: "
        // }
        if (error.name === 'TimeoutError') {
          error.message = `응답 대기 시간이 ${msec / 1000}초를 초과했습니다.`;
          console.log(`observeResponse(${requestId}) Tiemout`);
        } else {
          console.dir(error);
          this.logService.withToastrError(`observeMessage에서 에러 발생 : ${error}`);
        }
        reject(error);
      }, () => {
        console.log(`observeResponse(${requestId}) complete`);
      });
    });
  }

  handleResponse(message: Message<'response', any>) {
    let toastrMessage;
    switch (message.name) {
      case 'requestBaeminCertNo':
        toastrMessage = '배민 인증 요청';
        break;
      case 'acceptBaeminOrder':
        toastrMessage = `배민 주문 접수 : ${message.body.orderNo}`;
        break;
      case 'completeBaeminOrder':
        toastrMessage = `배민 주문 완료 : ${message.body.orderNo}`;
        break;
      case 'cancelBaeminOrder':
        toastrMessage = `배민 주문 취소 : ${message.body.orderNo}`;
        break;
      case 'getBaeminBlock':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;
      case 'postBaeminBlock':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;
      case 'postBaeminUnblock':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;

      case 'acceptCoupangeatsOrder':
        toastrMessage = `쿠팡이츠 주문 접수 : ${message.body.orderId}`;
        break;

      case 'cancelCoupangeatsOrder':
        toastrMessage = `쿠팡이츠 주문 취소 : ${message.body.orderId}`;
        break;

      case 'readyCoupangeatsOrder':
        toastrMessage = `쿠팡이츠 조리 완료 : ${message.body.orderId}`;
        break;

      case 'requestYogiyoRegister':
        toastrMessage = '요기요 재인증 성공';
        break;

      case 'acceptYogiyoOrder':
        toastrMessage = `요기요 주문 접수 : ${message.body.orderNo}`;
        break;

      case 'createVroongDelivery':
        toastrMessage = `배차 요청 : ${message.body.client_order_no}`;
        break;
      case 'cancelVroongDelivery':
        toastrMessage = `배송 취소 : ${message.body.delivery_id}`;
        break;
      case 'preparedCargoVroongDelivery':
        toastrMessage = `조리 완료 : ${message.body.deliveryId}`;
        break;
      case 'estimateVroongDelivery':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;
        // toastrMessage = `완료 예상 시간 : ${message.body.delivery_number}`;
        break;

      case 'restartQZTray':
        toastrMessage = `프린터 서버 재시작 : ${message.body.site}`;
        break;
      case 'printOrder':
        toastrMessage = `주문 인쇄 : ${message.body.orderId}`;
        break;
      case 'monit':
        toastrMessage = `서버 명령 : ${message.body.command}/${message.body.roomKey}`;
        break;
      case 'playSound':
        toastrMessage = '알림 재생 성공';
        break;
      case 'saveBaeminDeliveryRegion':
        // toastrMessage = '배달 권역 저장';
        return;
        break;
      case 'loadBaeminDeliveryRegion':
        // toastrMessage = '배달 권역 적용';
        return;
        break;
      default:
        toastrMessage = `알 수 없는 메시지 : ${message.name}`;
        break;
    }

    if (message.result === 'success') {
      this.utilService.toastrInfo(toastrMessage, '성공', 5000);
    } else if (message.result === 'error') {
      if (message.reason?.startsWith('이미')) {
        // 이미 취소 된 주문입니다. 이미 접수 된 주문입니다. 의 경우에는 #toe로 알림을 보내지 않기
        this.logService.withToastrWarn(`${toastrMessage}\n${message.reason ? message.reason : '원인 불명'}`, '실패', 30000);
      } else {
        this.logService.withToastrError(`${toastrMessage}\n${message.reason ? message.reason : '원인 불명'}`, '실패', 30000);
      }
    } else {
      this.logService.withToastrError(`이런 result : ${message.result}`, '실패', 600000);
    }
  }

  private async request<N extends keyof MessageBodyMap['request']>(
    name: N,
    to: MessagePeer,
    body: MessageBodyMap['request'][N],
    msec = 10000
  ): Promise<Message<'response', N>> {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const organization = this.userService.organization;
    const cmd: Message<'request', N> = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      organization,
      channel: 'message',
      from: {
        class: 'omc',
        instanceNo: instanceId,
        account: this.userService.user.email
      },
      to,
      type: 'request',
      name,
      body,
    };

    await this.db.doc<Message<'request', N>>(docRef).set(cmd);
    const message = await this.observeResponseWithTimeout(docId, msec) as Message<'response', N>;

    return message;
  }

  private notification<N extends keyof MessageBodyMap['notification']>(
    name: N,
    body: MessageBodyMap['notification'][N],
    email?: string
  ) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const organization = this.userService.organization;
    const cmd: Message<'notification', N> = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      organization,
      channel: 'message',
      from: {
        class: 'omc',
        instanceNo: instanceId,
        account: email ? email : this.userService.user.email
      },
      // to,
      type: 'notification',
      name,
      body,
    };

    return this.db.doc<Message<'notification', N>>(docRef).set(cmd);
  }

  public requestBaeminCertNo(instanceNo: string) {
    return this.request('requestBaeminCertNo', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
    }, 35000); // baemin-app-proxy가 30초 timeout이므로 5초 더 기다린다.
  }

  public requestAcceptBaeminOrder(instanceNo: string, orderNo: string, deliveryMinutes: number) {
    return this.request('acceptBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo,
      deliveryMinutes
    });
  }

  public requestCancelBaeminOrder(instanceNo: string, orderNo: string, cancelReasonCode: BaeminCancelReasonCode) {
    return this.request('cancelBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo,
      cancelReasonCode
    });
  }

  public requestCompleteBaeminOrder(instanceNo: string, orderNo: string) {
    return this.request('completeBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo
    });
  }

  /**
   * 배민의 영업운영중지 상태를 조회환다.
   */
  public requestGetBaeminBlock(instanceNo: string) {
    return this.request('getBaeminBlock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
    });
  }
  /**
   * 배민 영업운영중지 설정
   * @param temporaryBlockTime 분
   */
  public requestPostBaeminBlock(instanceNo: string, temporaryBlockTime: number) {
    return this.request('postBaeminBlock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      temporaryBlockTime
    });
  }
  /**
   * 배민 영업운영중지 해제
   */
  public requestPostBaeminUnblock(instanceNo: string) {
    return this.request('postBaeminUnblock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
    });
  }

  /**
   * 쿠팡이츠
   */
  public requestAcceptCoupangeatsOrder(instanceNo: string, orderId: string, duration: string) {
    return this.request('acceptCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId,
      duration
    });
  }

  public requestCancelCoupangeatsOrder(instanceNo: string, orderId: string, cancelReasonId: string, cancelType: 'DECLINE' | 'CANCEL') {
    return this.request('cancelCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId,
      cancelReasonId,
      cancelType
    });
  }

  public requestReadyCoupangeatsOrder(instanceNo: string, orderId: string) {
    return this.request('readyCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId
    });
  }

  /**
   * 요기요 재등록을 요청한다. 신규 업소를 추가하면 필요한 과정이다.
   */
  public requestYogiyoRegister() {
    return this.request('requestYogiyoRegister', {
      class: 'yogiyo-app-proxy',
      instanceNo: 'default'
    }, {
    }, 35000);
  }

  public requestAcceptYogiyoOrder(orderNo: string, deliveryMinutes: string) {
    return this.request('acceptYogiyoOrder', {
      class: 'yogiyo-app-proxy',
      instanceNo: 'default'
    }, {
      orderNo,
      deliveryMinutes
    });
  }

  /**
   * 부릉 배차를 메시지를 통해 명령한다.
   */
  public requestCreateVroongDelivery(instanceNo: string, body: RequestMessageBodyCreateVroongDelivery) {
    return this.request('createVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, body);
  }

  /**
   * 부릉 배차 취소를 메시지를 통해 명령한다.
   */
  public requestCancelVroongDelivery(instanceNo: string, deliveryId: string) {
    return this.request('cancelVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, {
      deliveryId
    });
  }

  /**
   * 부릉 조리 완료를 메시지를 통해 명령한다.
   */
  public requestPreparedCargoVroongDelivery(instanceNo: string, deliveryId: string) {
    return this.request('preparedCargoVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, {
      deliveryId
    });
  }

  /**
   * 부릉 조리 완료를 메시지를 통해 명령한다.
   */
  public requestEstimateVroongDelivery(instanceNo: string, deliveryId: string) {
    return this.request('estimateVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, {
      deliveryId
    });
  }

  public restartQZTray(instanceNo: string) {
    return this.request('restartQZTray', {
      class: 'printer-agent',
      instanceNo
    }, {
      site: instanceNo
    });
  }

  public notificationLogin(email: string, body: any = null) {
    return this.notification('login', body, email);
  }

  public notificationLogout() {
    return this.notification('logout', null);
  }

  public notificationLog(msg: string, context: any = null) {
    return this.notification('log', {
      msg,
      context
    });
  }

  public requestPrintOrder(instanceNo: string, orderId: string, printerKey: string, what: 'customer' | 'cook' | 'all', beep: boolean, autoPrint: boolean) {
    return this.request('printOrder', {
      class: 'printer-agent',
      instanceNo
    }, {
      orderId,
      printerKey,
      what,
      beep,
      autoPrint
    });
  }

  public requestMonit(command: 'start' | 'stop' | 'restart' | 'reload', siteKey: string, roomKey: string) {
    return this.request('monit', {
      class: 'site-agent',
      instanceNo: siteKey
    }, {
      command,
      siteKey,
      roomKey
    });
  }

  /**
   * monit 명령을 사용하지만 requestMonit과 다르게 정의했다.
   */
  public requestRestartProcess(siteKey: string, program: string, instanceNo: string) {
    return this.request('monit', {
      class: 'site-agent',
      instanceNo: siteKey
    }, {
      command: 'restart',
      siteKey,
      program,
      instanceNo
    });
  }

  /**
   * POS에 사운드 파일 출력 명령을 보낸다.
   * @param roomKey ex) 'gk-kangnam-28'
   */
  public playSoundForPos(roomKey: string, src: string[]) {
    return this.request('playSound', {
      class: 'pos',
      instanceNo: roomKey
    }, {
      src
    });
  }

  /**
   * 배민 배달 지역 백업 명령을 보낸다.
   */
  public requestSaveBaeminDeliveryRegion(instanceNo: string, shopNo: string, desc?: string) {
    return this.request('saveBaeminDeliveryRegion', {
      class: 'baemin-ceo-proxy',
      instanceNo
    }, {
      shopNo,
      desc
    }, 20000);
  }

  /**
   * 배민 배달 지역 백업본을 적용하는 명령을 보낸다.
   */
  public requestLoadBaeminDeliveryRegion(instanceNo: string, shopNo: string, docId: string) {
    return this.request('loadBaeminDeliveryRegion', {
      class: 'baemin-ceo-proxy',
      instanceNo
    }, {
      shopNo,
      docId
    });
  }
}

/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'journal',
  },
  {
    path: 'firestore',
    loadChildren: () => import('./pages/firestore/firestore.module').then(m => m.FirestoreModule),
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
  },
  {
    path: 'message',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/message/message.module').then(m => m.MessageModule)
  },
  {
    path: 'combinenet',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/combinenet/combinenet.module').then(m => m.CombinenetModule)
  },
  {
    path: 'log',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/log/log.module').then(m => m.LogModule)
  },
  {
    path: 'log-billing',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/log/log.module').then(m => m.LogModule)
  },
  {
    path: 'user',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'grouping',
    loadChildren: () => import('./pages/grouping/grouping.module').then(m => m.GroupingModule)
  },
  {
    path: 'journal',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/journal/journal.module').then(m => m.JournalModule)
  },
  {
    path: 'search-address',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/search-address/search-address.module').then(m => m.SearchAddressModule)
  },
  {
    path: 'book',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/book/book.module').then(m => m.BookModule)
  },
  {
    path: 'timetable',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/timetable/timetable.module').then(m => m.TimetableModule)
  },
  {
    path: 'sanitizer',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/sanitizer/sanitizer.module').then(m => m.SanitizerModule)
  },
  {
    path: 'daemon-switch',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/daemon-switch/daemon-switch.module').then(m => m.DaemonSwitchModule)
  },
  {
    path: 'order-generator',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/order-generator/order-generator.module').then(m => m.OrderGeneratorModule)
  },
  {
    path: 'playground',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/playground/playground.module').then(m => m.PlaygroundModule)
  },
  {
    path: 'log-order',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/log-order/log-order.module').then(m => m.LogOrderModule)
  },
  {
    path: 'unified-delivery',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/unified-delivery/unified-delivery.module').then(m => m.UnifiedDeliveryModule)
  },
  {
    path: 'barogo',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/barogo/barogo.module').then(m => m.BarogoModule)
  },
  {
    path: 'logiall',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/logiall/logiall.module').then(m => m.LogiallModule)
  },
  {
    path: 'manna',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/manna/manna.module').then(m => m.MannaModule)
  },
  {
    path: 'zendeli',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/zendeli/zendeli.module').then(m => m.ZendeliModule)
  },
  {
    path: 'moaplanet',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/moaplanet/moaplanet.module').then(m => m.MoaplanetModule)
  },
  {
    path: 'iudream',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/iudream/iudream.module').then(m => m.IudreamModule)
  },
  {
    path: 'baedalyo',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/baedalyo/baedalyo.module').then(m => m.BaedalyoModule)
  },
  {
    path: 'process-status',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/process-status/process-status.module').then(m => m.ProcessStatusModule)
  },
  {
    path: 'combinenet-delivery',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/combinenet-delivery/combinenet-delivery.module').then(m => m.CombinenetDeliveryModule)
  },
  {
    path: 'combinenet-delivery-range',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/combinenet-delivery-range/combinenet-delivery-range.module').then(m => m.CombinenetDeliveryRangeModule)
  },
  {
    path: 'barogo-delivery',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/barogo-delivery/barogo-delivery.module').then(m => m.BarogoDeliveryModule)
  },
  {
    path: 'barogo-delivery-range',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/barogo-delivery-range/barogo-delivery-range.module').then(m => m.BarogoDeliveryRangeModule)
  },
  {
    path: 'barogo-shop',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/barogo-shop/barogo-shop.module').then(m => m.BarogoShopModule)
  },
  {
    path: 'logiall-delivery',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/logiall-delivery/logiall-delivery.module').then(m => m.LogiallDeliveryModule)
  },
  {
    path: 'logiall-delivery-range',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/logiall-delivery-range/logiall-delivery-range.module').then(m => m.LogiallDeliveryRangeModule)
  },
  {
    path: 'coupangeats-app-order',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/coupangeats-app-order/coupangeats-app-order.module').then(m => m.CoupangeatsAppOrderModule)
  },
  {
    path: 'coupangeats-app-order-range',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/coupangeats-app-order-range/coupangeats-app-order-range.module').then(m => m.CoupangeatsAppOrderRangeModule)
  },
  {
    path: 'vroong-pos-delivery',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/vroong-pos-delivery/vroong-pos-delivery.module').then(m => m.VroongPosDeliveryModule)
  },
  {
    path: 'vroong-pos-delivery-range',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/vroong-pos-delivery-range/vroong-pos-delivery-range.module').then(m => m.VroongPosDeliveryRangeModule)
  },
  {
    path: 'baemin-app-detail-order',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/baemin-app-detail-order/baemin-app-detail-order.module').then(m => m.BaeminAppDetailOrderModule)
  },
  {
    path: 'baemin-app-list-order',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/baemin-app-list-order/baemin-app-list-order.module').then(m => m.BaeminAppListOrderModule)
  },
  {
    path: 'baemin-ceo-shops-open',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/baemin-ceo-shops-open/baemin-ceo-shops-open.module').then(m => m.BaeminCeoShopsOpenModule)
  },
  {
    path: 'centrex-callback',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/centrex-callback/centrex-callback.module').then(m => m.CentrexCallbackModule)
  },
  {
    path: 'combinenet-callback',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/combinenet-callback/combinenet-callback.module').then(m => m.CombinenetCallbackModule)
  },
  {
    path: 'combinenet-shop',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/combinenet-shop/combinenet-shop.module').then(m => m.CombinenetShopModule)
  },
  {
    path: 'kicc-order',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/kicc-order/kicc-order.module').then(m => m.KiccOrderModule)
  },
  {
    path: 'kicc-result',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/kicc-result/kicc-result.module').then(m => m.KiccResultModule)
  },
  {
    path: 'kicc-notification',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/kicc-notification/kicc-notification.module').then(m => m.KiccNotificationModule)
  },
  {
    path: 'vroong-pos-options-pickup',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/vroong-pos-options-pickup/vroong-pos-options-pickup.module').then(m => m.VroongPosOptionsPickupModule)
  },
  {
    path: 'yogiyo-app-list-order',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/yogiyo-app-list-order/yogiyo-app-list-order.module').then(m => m.YogiyoAppListOrderModule)
  },
  {
    path: 'sms-receive',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/sms-receive/sms-receive.module').then(m => m.SmsReceiveModule)
  },
  {
    path: 'manna-delivery',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/manna-delivery/manna-delivery.module').then(m => m.MannaDeliveryModule)
  },
  {
    path: 'manna-delivery-range',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/manna-delivery-range/manna-delivery-range.module').then(m => m.MannaDeliveryRangeModule)
  },
  { path: 'functions-ping', loadChildren: () => import('./pages/functions-ping/functions-ping.module').then(m => m.FunctionsPingModule) },
  {
    path: 'direct-analytics',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/direct-analytics/direct-analytics.module').then(m => m.DirectAnalyticsModule)
  },
  {
    path: 'account-docs',
    canActivate: [AuthGuardService, InitGuardService],
    loadChildren: () => import('./pages/account-docs/account-docs.module').then(m => m.AccountDocsModule)
  },
  // {
  //   path: 'dev',
  //   loadChildren: './dev/dev.module#DevModule',
  //   canLoad: [AuthGuardService],
  //   canActivate: [AuthGuardService]
  // },
  {
    path: '**',
    redirectTo: 'journal'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
